import React from 'react';
import { Link } from 'react-router-dom';
import resume from './images/OrsResume.pdf';

export default function Resume() {
  return (
    <div className="resume-page">
      <section id="resume-header">
        <img
          src="https://avatars3.githubusercontent.com/u/11906502?s=460&v=4"
          id="resume-profile-image"
          alt="Daniel Ors smiling for the camera!"
        />
        <h1>Daniel Ors</h1>
        <h2>Senior Software Engineer & Full Stack Developer</h2>
        <h3 id="clearance">United States Citizen - Secret Clearance (Interim TS)</h3>
        <ul id="contact-list">
          <li>
            <a href="mailto:hiredanielors@gmail.com" className="collab-name">
              hiredanielors@gmail.com
            </a>
          </li>
          <li>Seattle, WA</li>
          {/* <li>
            <Link
              to={resume}
              target="_blank"
              rel="noopener noreferrer"
              className="collab-name"
            >
              PDF version
            </Link>
          </li> */}
        </ul>
      </section>
      <hr />
      <section>
        <h2>Summary</h2>
        <p>
          Senior Software Engineer & Full Stack Developer with demonstrated
          success in lean B2B startup, agency, and nonprofit environments.
          Skilled in modern JavaScript frameworks. Driven to make a difference
          given any challenge and focused on delivering feature-complete
          applications for federal and enterprise-level clients on time and
          under budget.
        </p>
      </section>
      <hr />
      <section>
        <h2>Skills</h2>
        <p>
          <b>Advanced:​</b> Python, D3.js, Express, Knex, bcrypt, JWT,
          Cypress.io, Mocha, Chai, Jest, Enzyme
        </p>
        <p>
          <b>Proficient:​</b> React, jQuery, Oracle PL/SQL, PostgreSQL, NPM,
          Node.js, REST APIs, Git, GitHub/GitLab, Agile Scrum, Proposal Writing
        </p>
        <p>
          <b>Expert:​</b> ​JavaScript, Release Engineering, MetricStream GRC
          Software, HTML5, CSS3, SQL
        </p>
      </section>
      <hr />
      <section>
        <h2>Experience</h2>
        <br />
        <h3>Elevate Government Solutions</h3>
        <p>
          <i>Senior Software Engineer</i>
        </p>
        <p>Washington, D.C. / Remote</p>
        <p>August 2019 – Present</p>
        <br />
        <p>
          Led team of developers in implementation of customized MetricStream software for a federal client.
        </p>
        <p>
          Built customized release automations and optimizations for MetricStream software to reduce risk and deployment delivery time.
        </p>
        <p>
          Lead release engineer for 3 annual software upgrade lifecycles, including 2 COTS vendor upgrades and project-wide go-live windows.
        </p>
        <p>
          Identified gaps, designed workarounds and product
          extensions/customizations as required to meet client needs.
        </p>
        <p>
          Coordinated with the Project Manager and Business Analysts
          and others to ensure implementations are completed on time and within
          budget.
        </p>
        <p>
          Supported proposal response efforts, contributing to corporate success
          and growth.
        </p>
        <br />
        <br />
        <h3>Dewey Square Group</h3>
        <p>
          <i>Consultant - Web Development and Digital Strategy</i>
        </p>
        <p>Washington, D.C. / Remote</p>
        <p>December 2017 – December 2019</p>
        <br />
        <p>
          Consulted on digital strategy and development needs for political
          advocacy and communications.{' '}
        </p>
        <p>
          Developed and maintained websites for clients with over 400,000
          monthly users.
        </p>
        <p>Serviced clients in non-profit, public, and private sectors. </p>
        <p>Managed Google Ads accounts with over $500,000 in annual value.</p>
        <p>
          Supported RFI and RFP response efforts, contributing to major client
          wins.
        </p>
        <br />
        <br />
        <h3>Alexander Graham Bell Association</h3>
        <p>
          <i>Digital Marketing Manager</i>
        </p>
        <p>Washington, D.C.</p>
        <p>April 2015 – November 2017</p>
        <br />
        <p>Managed digital marketing team and all digital campaigns.</p>
        <p>
          Web development lead - charged with management of 6 website
          properties.
        </p>
        <p>
          Oversight of organizational rebranding and website redesign for 2017
          launch.
        </p>
        <p>
          Managed paid search & paid social campaigns - 410% increase in
          traffic.
        </p>
        <p>
          Produced scheduled communications for an audience of over 40,000
          readers.
        </p>
        <br />
        <br />
        <h3>Fueled</h3>
        <p>
          <i>Marketer</i>
        </p>
        <p>New York, New York</p>
        <p>August 2014 – March 2015</p>
        <br />
        <p>Managed team of writers, lead editor of content.</p>
        <p>Produced scheduled communications for audience of 100,000+.</p>
        <p>Achieved 16% increase in monthly web traffic during tenure.</p>
      </section>
      <hr />
      <section>
        <h2>Recent Projects</h2>
        <p>
          <b>
            <a
              href="https://mycongress.now.sh"
              target="_blank"
              rel="noopener noreferrer"
              className="collab-name"
            >
              MyCongress
            </a>
            :
          </b>{' '}
          An app that brings Congressional data to your fingertips. Built in an
          Agile/Scrum development process with ReactJS, Node, Express,
          PostgreSQL, D3.js, Cypress, Mocha, Chai, and secured with bcrypt/JWT.
          Collaborative project with{' '}
          <a
            href="https://github.com/owencyr"
            target="_blank"
            rel="noopener noreferrer"
            className="collab-name"
          >
            Owen Cyr
          </a>
          ,{' '}
          <a
            href="https://github.com/secilreel"
            target="_blank"
            rel="noopener noreferrer"
            className="collab-name"
          >
            Irem Seçil Reel Sen
          </a>
          ,{' '}
          <a
            href="https://github.com/lahrime"
            target="_blank"
            rel="noopener noreferrer"
            className="collab-name"
          >
            Ali Lahrime
          </a>
          , and{' '}
          <a
            href="https://github.com/paypay43"
            target="_blank"
            rel="noopener noreferrer"
            className="collab-name"
          >
            Payman Kossari
          </a>
          .
        </p>
        <br />
        <p>
          <b>
            <a
              href="https://refreshd.now.sh"
              target="_blank"
              rel="noopener noreferrer"
              className="collab-name"
            >
              Refreshd​
            </a>
            :
          </b>{' '}
          A full stack food sharing platform. Built with React, Node, Express,
          PostgreSQL, and secured with bcrypt and JWT.
        </p>
        <br />
        <p>
          <b>
            <a
              href="https://andrew-daniel-spaced-repetition.now.sh"
              target="_blank"
              rel="noopener noreferrer"
              className="collab-name"
            >
              Learn.js
            </a>
          </b>
          : An application that utilizes spaced repetition to help users learn
          JavaScript! Built with React, Node, Express, PostgreSQL, a queue-based
          spaced repetition algorithm, and secured with bcrypt and JWT.
          Collaborative project with{' '}
          <a href="https://github.com/andrewbituin" className="collab-name">
            Andrew Bituin
          </a>
          .
        </p>
        <br />
        <p>
          <b>
            <a
              href="http://disabilities.io"
              target="_blank"
              rel="noopener noreferrer"
              className="collab-name"
            >
              Disabilities.io​
            </a>
            :
          </b>{' '}
          An informational project to make the Americans with Disabilities Act
          more accessible. Strictly HTML5/CSS3 for the moment, but planning out
          a roadmap to convert to React with an Express/PSQL backend for
          commenting and user accounts.
        </p>
      </section>
      <hr />
      <section>
        <h2>Education</h2>
        <br />
        <h3>Dartmouth College</h3>
        <p>
          <i>Bachelor of Arts: Government, Political Theory</i>
        </p>
        <p>Graduation: June 2014</p>
      </section>
    </div>
  );
}
